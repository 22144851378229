.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.header h1 {
    margin: 0;
}

.dropdown {
    position: relative;
    z-index: 30;
}

.dropdown button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
}

.dropdown-content {
    border-radius: 8px;
    position: absolute;
    top: 100%;
    right: 8%;
    z-index: 10;
    background: white;
    border: 1px solid #ccc;
    width: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.dropdown-content p {
    margin: 0;
    padding: 10px;
    cursor: pointer;
}

.dropdown-content p:hover {
    background: #f0f0f0;
}