.home-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    /*height: 450px;*/
    background-color: white;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    /* Combined border-color with border width */
    overflow: hidden;
    position: relative;
    user-select: none;
    /* Prevent text selection */
}

.detail-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Updated to a valid box-shadow value */
    height: 700px;
    padding: 1.2%;
    background-color: white;
    background-size: cover;
    background-position: center;
    /* Combined border-color with border width */
    overflow: hidden;
    position: relative;
    user-select: none;
    /* Prevent text selection */
}

.page-view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 385px;
}

.cards-container {
    position: relative;
    cursor: grab;
    width: 100%;
    transition: transform 0.2s ease-out;
    border-color: black;
    border-radius: 15px;
    height: 15px;
    z-index: 0;
}

.cards-container:active {
    cursor: grabbing;
}

.card-wrapper {
    position: absolute;
    width: 100%;
}