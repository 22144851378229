.card {
    width: auto;
    aspect-ratio: 16 / 9;
    background-size: cover;
    background-position: center;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    /* Prevent text selection */
}

.card-content {
    padding: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    /* Prevent text selection */
}

.card-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: start;
    user-select: none;
    /* Prevent text selection */
}

.card-column-items {
    padding: 0px;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    /* Prevent text selection */
}

.card-column-items.alignItemsStart {
    align-items: start;
}

.card-column-items.alignItemsEnd {
    align-items: end;
}

.card-title {
    margin: 0;
    user-select: none;
    /* Prevent text selection */
}

.card-subtitle {
    margin: 0;
    font-size: 0.9em;
    color: #ccc;
    user-select: none;
    /* Prevent text selection */
}



.card-amount {
    margin: 0;
    user-select: none;
    /* Prevent text selection */
}

.card-change {
    margin: 0;
    color: #4caf50;
    /* Green color for positive change */
    user-select: none;
    /* Prevent text selection */
}

.card-footer {
    text-align: start;
    user-select: none;
    /* Prevent text selection */
}

.plan,
.name,
.virtual-text {
    margin: 0;
    font-size: 0.9vw;
    user-select: none;
    /* Prevent text selection */
}